import { styled } from '../../../stitches.config';
import * as React from 'react';

const StyledLoaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const LoaderContainer: React.FC = (props: any) => <StyledLoaderContainer {...props} />;
