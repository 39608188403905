import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from '@components/Layout';
import { Loader } from '@components/Loader';
import { Button } from '@cutters-digital/wig-js';
import { useLocalStorage } from 'hooks';
import type { NextPage, GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Index: NextPage = () => {
  const { t } = useTranslation('common');
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const [locale] = useLocalStorage('locale', 'en');
  const router = useRouter();

  useEffect(() => {
    redirectToHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, locale, router]);

  const redirectToHome = () => {
    if (isAuthenticated) {
      router.push('/home', '/home', { locale: locale }).then(() => router.reload());
    }
  };

  if (isLoading || isAuthenticated) {
    return (
      <Layout type="centered">
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout type="centered">
      <Button onClick={loginWithRedirect}>{t('login')}</Button>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common', 'errors', 'cutNote'])),
    },
  };
};

export default Index;
