import { loaderElements as Elements, BLADES_COUNT } from './LoaderElements';
import type * as Stitches from '@stitches/react';
import * as React from 'react';

type LoaderProps = {
  size?: number;
  css?: Stitches.CSS;
};

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { size = 7, css, ...rest } = props;
  return (
    <Elements.spinner css={{ fontSize: size * 8, ...css }} {...rest}>
      {[...Array(BLADES_COUNT)].map((_, index) => (
        <Elements.blade key={index} />
      ))}
    </Elements.spinner>
  );
};
