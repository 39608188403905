import { keyframes } from '@stitches/react';
import { styled } from 'stitches.config';

export const BLADES_COUNT = 12;

const spinnerFade = keyframes({
  from: { backgroundColor: '$gray300' },
  to: { backgroundColor: 'transparent' },
});

const getNthBladeStyles = () => {
  const nthBladeStyles = {} as any;
  let animationDelay = 0;
  let bladeRotations = 0;

  for (let i = 0; i < 12; i++) {
    nthBladeStyles[`&:nth-child(${i + 1})`] = {
      animationDelay: `${animationDelay}s`,
      transform: `rotate(${bladeRotations}deg)`,
    };
    animationDelay += 0.083;
    bladeRotations += 360 / BLADES_COUNT;
  }

  return nthBladeStyles;
};

export const loaderElements = {
  spinner: styled('div', {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    position: 'relative',
  }),
  blade: styled('div', {
    position: 'absolute',
    left: '0.4629em',
    bottom: 0,
    width: '0.074em',
    height: '0.2777em',
    borderRadius: '0.5em',
    backgroundColor: 'transparent',
    transformOrigin: 'center -0.2222em',
    animation: `${spinnerFade} 1s infinite linear`,
    ...getNthBladeStyles(),
  }),
};
